html, body {
  height: 100%;
  width: 100%;
}

body {
  box-sizing: border-box;
  display: flex;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

#root {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  }

#__protoProxy {
  display: none;
}

.aigrading .rsis-container > div {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;
}

.aigrading .aigrading-image-slider-cont button {
  visibility: hidden;
}

/* Invisible datepicker fix */
body #fluent-default-layer-host {
  z-index: 10000000001 !important;
}